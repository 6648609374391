import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

type Props = {
  label: string;
  value: string;
  isAuth: string;
  handleChange: (e: React.ChangeEvent<typeof FormControl & HTMLInputElement>) => void;
  id: string;
  disabled: boolean;
};

const FormRadio: React.FC<Props> = ({ label, value, isAuth, handleChange, id, disabled }) => {
  return (
    <Form.Check
      type="radio"
      label={label}
      value={value}
      onChange={handleChange}
      defaultChecked={isAuth === value}
      checked={isAuth === value}
      className="me-3"
      key={label}
      name={`${label}${id}`}
      id={`${label}${id}`}
      disabled={disabled}
      style={{ cursor: 'pointer' }}
    />
  );
};

export default FormRadio;
