import React, { memo, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  mainSelectedBelongState,
  subSelectedAuthState,
  subSelectedBelongState,
} from '../../../states/atom/RegisterFormState';
import { subBelongGroupSelectSelector } from '../../../states/selector/SubBelongSelectSelector';
import { ToolTip } from '../../atoms/Tooltip';
import {
  TobAccountSubContractShopRowDataResponse,
  TobAccountSubHierarchyRowDataResponse,
  TobAccountSubOrganizationRowDataResponse,
} from '../../../api-client';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { useParamClientAll } from '../../../hooks/useParamClientAll';
import { SharedRadioParamContext } from '../../../hooks/useNewRadio';
import { MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';

export type SelectType = {
  id: string;
  value: string;
  subRefId?: number;
};

export type Shoptype = {
  id: string;
  value: string;
  shop: { [key: string]: SelectType };
};
type Orgtype = {
  id: string;
  value: string;
  org: { [key: string]: Shoptype };
};

export type ClientType = {
  client: { [key: string]: Orgtype };
};

const content = 'ユーザの権限を設定できます。所属する店舗や部署などを設定するのが一般的です。';

export const SubBelongSelectGroup: React.FC = memo(() => {
  const { pathname } = useLocation();
  const params = useUrlParams();
  const paramAllClient = useParamClientAll();
  const accountId: string | undefined = params.accountUpdateId === '' ? '' : `:${params.accountUpdateId}`;
  const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
  const strAllClientId: string | undefined = paramAllClient === '' ? '' : `:${paramAllClient}`;
  // メインで選択した権限（本社やグループ等）
  const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
  // メインで選択した権限（本社やグループ等）
  const [workHierarchyLevel, setWorkHierarchyLevel] = useState<number>(0);
  // サブで選択した権限
  const subAuth = useRecoilValue(subSelectedAuthState(strAccountId));
  // サブ所属の選択したID
  const setIsSubBelong = useSetRecoilState(subSelectedBelongState(strAccountId));
  // 選択した所属をatomで管理（サブ所属で使用）
  const [mainSelect, setMainSelect] = useRecoilState(mainSelectedBelongState(strAccountId));
  // メイン所属変更したFlg
  const { mainEditFlg, setMainEditFlg } = useContext(MainBelongFlgContext);
  // サブ所属の一覧（グループ選択時）
  const subBelongGroups = useRecoilValue(subBelongGroupSelectSelector(mainSelect + accountId + strAllClientId));

  useEffect(() => {
    // const code = defaultValues?.split(',');
    // ラジオボタン選択
    // if (mainAuth === '1' && selectList) {
    // 選んだものを候補から削除しない仕様の場合の初期値はリストの一個目に設定しておく
    if (subAuth === '3') {
      if (subBelongGroups && subBelongGroups?.contractShop.length > 0) {
        setIsSubBelong(subBelongGroups.contractShop[0].contractShopId);
      }
    } else if (subAuth !== '1' && subAuth !== '3') {
      const find = subBelongGroups.organization.find((it) => it.hierarchyId === subAuth); // && !it.hierarchyFlg);
      if (find) {
        setIsSubBelong(find!.organizationId);
      }
    }
    // 組織から
    if (mainAuth.includes('H')) {
      subBelongGroups.hierachyLevel.map((hie: TobAccountSubHierarchyRowDataResponse, i) => {
        // 階層IDが同じなら表示
        // eslint-disable-next-line no-nested-ternary
        if (hie.hierarchyId === mainAuth) {
          setWorkHierarchyLevel(hie.hierarchyLevel!);
        }
      });
    }
  }, []);

  useEffect(() => {
    // const code = defaultValues?.split(',');
    // ラジオボタン選択
    // if (mainAuth === '1' && selectList) {
    // 選んだものを候補から削除しない仕様の場合の初期値はリストの一個目に設定しておく
    if (subAuth === '3') {
      if (subBelongGroups && subBelongGroups.contractShop.length > 0) {
        setIsSubBelong(subBelongGroups.contractShop[0].contractShopId);
      }
    } else if (subAuth !== '1' && subAuth !== '3') {
      const find = subBelongGroups.organization.find((it) => it.hierarchyId === subAuth); // && !it.hierarchyFlg);
      if (find) {
        setIsSubBelong(find!.organizationId);
      }
    }
    // 組織から
    if (mainAuth.includes('H')) {
      subBelongGroups.hierachyLevel.map((hie: TobAccountSubHierarchyRowDataResponse, i) => {
        // 階層IDが同じなら表示
        // eslint-disable-next-line no-nested-ternary
        if (hie.hierarchyId === mainAuth) {
          setWorkHierarchyLevel(hie.hierarchyLevel!);
        }
      });
    }
  }, [subAuth]);

  return (
    <>
      {subAuth.includes('H') && mainAuth.includes('H') && (
        <ToolTip content={content}>
          {subBelongGroups && subBelongGroups.organization ? (
            <Form.Select onChange={(e) => setIsSubBelong(e.target.value)} key={content} disabled={mainEditFlg}>
              <>
                {subBelongGroups.organization.map((g: TobAccountSubOrganizationRowDataResponse, i) =>
                  // 階層IDが同じなら表示
                  // eslint-disable-next-line no-nested-ternary
                  subAuth === g.hierarchyId && workHierarchyLevel <= g!.hierarchyLevel! ? (
                    <option key={g.organizationId} value={g.organizationId}>
                      {g.organizationName}
                    </option>
                  ) : (
                    <></>
                  )
                )}
              </>
            </Form.Select>
          ) : (
            <></>
          )}
        </ToolTip>
      )}
      {subAuth === '3' && (
        <ToolTip content={content}>
          <Form.Select onChange={(e) => setIsSubBelong(e.target.value)} disabled={mainEditFlg}>
            <>
              {mainAuth.includes('H') ? (
                <>
                  <option disabled>---店舗----------</option>
                  {subBelongGroups.contractShop.map((s: TobAccountSubContractShopRowDataResponse) =>
                    mainSelect !== s.contractShopId ? (
                      <option key={s.contractShopId} value={s.contractShopId}>
                        {s.contractShopName}
                      </option>
                    ) : (
                      <></>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
              {mainAuth === '3' ? (
                <>
                  <option disabled>---店舗----------</option>
                  {subBelongGroups?.contractShop.map((s: TobAccountSubContractShopRowDataResponse) =>
                    mainSelect !== s.contractShopId ? (
                      <option key={s.contractShopId} value={s.contractShopId}>
                        {s.contractShopName}
                      </option>
                    ) : (
                      <></>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          </Form.Select>
        </ToolTip>
      )}
    </>
  );
});
