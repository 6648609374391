import React, { useState } from 'react';
import scss from '../../scss/atoms/tool-tip.module.scss';

type Props = {
  content: string;
  className?: string;
  toolTipClassName?: string;
  isToolTipDisplay?: boolean;
};

export const ToolTip: React.FC<Props> = ({
  content,
  children,
  className,
  toolTipClassName,
  isToolTipDisplay = true,
}) => {
  return (
    <div className={`${scss.container} ${className}`}>
      <div>{children}</div>
      {isToolTipDisplay && <div className={`${scss.tool_tip} ${toolTipClassName}`}>{content}</div>}
    </div>
  );
};
