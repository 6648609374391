import React, { memo, useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountApi,
  TobAccountCreateFormResponse,
  TobAccountInfoOutputRowDataResponse,
  TobAccountSubListOutputResponse,
} from '../../../api-client';
import scss from '../../../scss/organisms/registerUser.module.scss';
import { SubmitLoadingButton } from '../../atoms/Button/UnitButton';
import { PasswordForm, TextForm } from './TextForm';
import { schema } from '../../../services/registerFormValidation';
import { RadioGroup } from '../../molecules/Form/RadioGroup';
import { BelongFormSelectGroup } from '../../molecules/Form/BelongFormSelectGroup';
import { AuthCheckFormGroup } from '../../molecules/Form/CheckGroup';
import { SharedRadioParamProvider } from '../../../hooks/useNewRadio';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { MainBelongFlgParamProvider, MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';
import { SpinLoading } from '../../molecules/Loading/Loading';
import { useParamClientAll } from '../../../hooks/useParamClientAll';
import { OtherBelongFormSelectGroup } from '../../molecules/Form/OtherBelongFormSelectGroup';
import { SelectType } from '../../molecules/Form/SubBelongSelectGroup';
import { noticeToastState } from '../../../states/atom/CommonPageState';
import { MASTER_CLIENT } from '../../../constants/MasterClient';

type Props = {
  myAccount: TobAccountInfoOutputRowDataResponse;
};

export const EditAccount: React.FC<Props> = memo(({ myAccount }) => {
  const [error, setError] = useState<any | undefined>();
  const noChange = true;
  const loginUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [tobAccountRadioList, setTobAccountRadioList] = useState<TobAccountSubListOutputResponse>();
  const paramAllClient = useParamClientAll();
  const setNotice = useSetRecoilState(noticeToastState);
  const [roiAdminDisplayFlg, setRoiAdminDisplayFlg] = useState<boolean>(false);
  const fancrewAdminClientFlg = MASTER_CLIENT.clientId.some((v) => v === Number(paramAllClient));

  // 選択したサブ所属の一覧
  const [subSelectedList, setSubSelectedList] = useState<SelectType[]>([]);
  // メインのSelectBoxのリセット
  const [mainSelectResetFlg, setMainSelectResetFlg] = useState<boolean>(false);
  const [subSelectResetFlg, setSubSelectResetFlg] = useState<boolean>(false);

  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TobAccountCreateFormResponse>({
    resolver: yupResolver(schema),
  });

  const [defaultValues, setDefaultValues] = useState<TobAccountCreateFormResponse>({
    belong: myAccount.belongCode.toString(),
    clientAdminFlg: myAccount.clientAdminFlg,
    confirmePassword: '',
    giftViewFlg: myAccount.giftViewFlg,
    goalSetFlg: myAccount.goalSetFlg,
    initPassword: false,
    mailSendFlg: myAccount.mailSendFlg,
    mailaddress: myAccount.mailaddress,
    name: myAccount.name,
    password: '',
    roiAdminFlg: myAccount.roiAdminFlg,
    userEditFlg: myAccount.userEditFlg,
    viewAllFlg: myAccount.viewAllFlg,
  });

  useEffect(() => {
    // useFormの値を上書き
    reset(defaultValues);

    // マウント状態をトラック
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      handleTobAccountRadioList();
      // 社内管理ユーザフラグ
      setRoiAdminDisplayFlg(fancrewAdminClientFlg && loginUser.roiAdminFlg);
    }
    return () => {
      // クリーンアップでアンマウントを記録
      isMounted = false;
    };
  }, [defaultValues]);

  const handleTobAccountRadioList = async () => {
    const api = new AccountApi();
    try {
      const res = await api.tobAccountRadioList(undefined, undefined, paramAllClient);
      setTobAccountRadioList(res.data);
    } catch (e) {
      console.error('Failed to fetch tobAccountRadioList:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: TobAccountCreateFormResponse) => {
    setError(undefined);
    const api = new AccountApi();
    await api
      .tobAccountUpdate({
        ...data,
        belongCode: data.belong,
        id: myAccount.id.toString(),
        tobAccountClientOrgShopId: myAccount.tobAccountClientOrgShopId.toString(),
        password: data.password === '' ? undefined : data.password,
        confirmePassword: data.confirmePassword === '' ? undefined : data.confirmePassword,
        initPassword: false,
      })
      .then((res) => {
        if (res.data.result === undefined || res.data.result) {
          handleTransition();
          setNotice('アカウントの編集が完了しました。');
        } else {
          setError(res.data.error);
          setNotice('予期しないエラーが発生しました。しばらくしてからもう一度お試しください。');
        }
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  const handleTransition = () => {
    handleTobAccountRadioList();
  };

  const WrapRadioGroup = () => {
    if (isLoading && !tobAccountRadioList) {
      return (
        <>
          <div className={`d-flex justify-content-center align-items-center ${scss.spiner_min_wrapper}`}>
            <SpinLoading />
          </div>
        </>
      );
    }
    if (!isLoading && tobAccountRadioList) {
      return (
        <>
          <RadioGroup data={tobAccountRadioList} setValue={setValue} defaultValues={myAccount} noChange />
        </>
      );
    }
    return <p className="h5 font-bold p-4">データが存在しませんでした。</p>;
  };

  const WrapBelongFormSelectGroup = memo(() => {
    if (isLoading && !tobAccountRadioList) {
      return <></>;
    }
    if (!isLoading && tobAccountRadioList) {
      return (
        <>
          <BelongFormSelectGroup
            register={register}
            setValue={setValue}
            defaultValues={myAccount.belong}
            submitting={isSubmitting}
            noChange
            error={error}
          />
        </>
      );
    }
    return <p className="h5 font-bold p-4">データが存在しませんでした。</p>;
  });

  return (
    <Card className={scss.card}>
      アカウントを編集します。
      <br />
      パスワードに使用できる文字は英数字(A-Z、a-z、0-9)です。また必ず英字、数字それぞれ1文字以上含んでいる必要があります。
      <br />
      パスワードを変更しない場合は省略可能です。
      <br />
      権限を設定すると、対象の権限以下を参照することができるようになります。
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextForm register={register} errors={errors} error={error} submitting={isSubmitting} />
        <PasswordForm register={register} errors={errors} error={error} submitting={isSubmitting} />
        <div className="fw-bold mt-2">所属</div>
        <SharedRadioParamProvider loginTobAccountId={loginUser!.clientIdAccountId} newPageFlg={false}>
          <MainBelongFlgParamProvider>
            <WrapRadioGroup />
            <WrapBelongFormSelectGroup />
            <OtherBelongFormSelectGroup
              submitting={isSubmitting}
              subSelectedList={subSelectedList}
              subSelectDel={() => {}}
              setSubSelectedList={setSubSelectedList}
              setMainSelectResetFlg={setMainSelectResetFlg}
              setSubSelectResetFlg={setSubSelectResetFlg}
              subSelectResetFlg={subSelectResetFlg}
              mainSelectResetFlg={mainSelectResetFlg}
              isBelongChange={false}
              isSubRefDelete={false}
            />
            <div className="fw-bold mt-2">権限</div>
            <AuthCheckFormGroup
              register={register}
              getValues={getValues}
              setValue={setValue}
              submitting={isSubmitting}
              roiAdminDisplayFlg={roiAdminDisplayFlg}
              noChange={noChange}
              myAccount={myAccount}
            />
          </MainBelongFlgParamProvider>
        </SharedRadioParamProvider>
        <div className="pt-3">
          <SubmitLoadingButton label="変更" submitting={isSubmitting} />
        </div>
      </form>
    </Card>
  );
});
