import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UseFormSetValue } from 'react-hook-form';
import { FormControl } from 'react-bootstrap';
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil';
import {
  belongDataListState,
  belongRadioSelectedFlgState,
  mainBelongEditFlgState,
  mainSelectedAuthState,
  subEditFlgState,
} from '../../../states/atom/RegisterFormState';
import FormRadio from '../../atoms/Form/FormRadio';
import {
  TobAccountCreateFormResponse,
  TobAccountInfoOutputRowDataResponse,
  TobAccountSubListOutputResponse,
} from '../../../api-client';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { SharedRadioParamContext } from '../../../hooks/useNewRadio';
import { MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';

type Props = {
  data: TobAccountSubListOutputResponse;
  setValue: UseFormSetValue<TobAccountCreateFormResponse>;
  // defaultValues: TobAccountCreateFormResponse;
  defaultValues?: TobAccountInfoOutputRowDataResponse;
  noChange?: boolean;
};

type BelongProps = {
  label: string;
  value: string;
};

export const RadioGroup: React.FC<Props> = ({ data, setValue, defaultValues, noChange = false }) => {
  const [belongList, setBelongList] = useState<BelongProps[]>();
  const params = useUrlParams();
  const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
  const [, setBelongData] = useRecoilState(belongDataListState(strAccountId));
  const [radioSelected] = useRecoilState(belongRadioSelectedFlgState(strAccountId));
  const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
  // サブ所属変更したFlg
  const [subEditFlg] = useRecoilState(subEditFlgState(strAccountId));
  // サブで選択した権限
  const { mainEditFlg, setMainEditFlg } = useContext(MainBelongFlgContext);
  const handleChange = useCallback((e: React.ChangeEvent<typeof FormControl & HTMLInputElement>) => {
    setMainAuth(e.target.value);
    setMainEditFlg(true);
    if (e.target.value !== '1') {
      setValue('clientAdminFlg', false);
      setValue('roiAdminFlg', false);
    }
  }, []);

  useEffect(() => {
    const belong = defaultValues?.belongCode;
    if (mainAuth === '') {
      if (radioSelected === null) {
        if (belong !== undefined && belong !== '') {
          const split = belong.split(',');
          let status = '1';
          //
          if (split[0] === 'C') {
            status = '1';
          } else if (split[0] === 'O') {
            data.organization.map((org) => {
              if (org.organizationId === belong) {
                status = org.hierarchyId!;
              }
            });
          } else if (split[0] === 'S') {
            status = '3';
          }
          // setMainEditFlg(true);
          setMainAuth(status!);
        }
      }
    }
    // Client,Organization,ContractShop情報を格納
    setBelongData(data);
    const hierarchyData: BelongProps[] = [];
    data.hierachyLevel.map((h) => {
      if (h) {
        hierarchyData.push({ label: h.hierarchyName!.toString(), value: h.hierarchyId!.toString() });
      }
    });
    if (data.client.length > 0 && data.organization.length > 0 && data.contractShop.length > 0) {
      setBelongList([{ label: '本社', value: '1' }, ...hierarchyData, { label: '店舗', value: '3' }]);
    } else if (data.client.length === 0 && data.organization.length > 0) {
      setBelongList([...hierarchyData, { label: '店舗', value: '3' }]);
    } else if (data.client.length > 0 && data.contractShop.length > 0) {
      setBelongList([
        { label: '本社', value: '1' },
        { label: '店舗', value: '3' },
      ]);
    } else if (data.client.length === 0 && data.organization.length === 0 && data.contractShop.length > 0) {
      setBelongList([{ label: '店舗', value: '3' }]);
    }
  }, []);

  return (
    <div className="mt-2 mb-3 d-flex align-items-center">
      {belongList &&
        belongList.map((o) => (
          <FormRadio
            label={o.label}
            value={o.value}
            handleChange={handleChange}
            isAuth={mainAuth}
            key={o.label}
            id="main-create"
            disabled={noChange || subEditFlg}
          />
        ))}
    </div>
  );
};
