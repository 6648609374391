import React, { Dispatch, memo, SetStateAction, useContext, useEffect, useState } from 'react';
import { TiDelete } from 'react-icons/ti';
import { AxiosResponse } from 'axios';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import scss from '../../../scss/organisms/registerUser.module.scss';
import { SelectType } from './SubBelongSelectGroup';
import { ModalProps, SubBelongModal2 } from '../../organisms/Modal/SubBelongModal2';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { AccountApi, ResultOutputResponse, TobAccountSubRefListOutputResponse } from '../../../api-client';
import { mainBelongEditFlgState, mainSelectedBelongState } from '../../../states/atom/RegisterFormState';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useParamClientAll } from '../../../hooks/useParamClientAll';
import { SpinLoading } from '../Loading/Loading';
import { MASTER_CLIENT } from '../../../constants/MasterClient';
import { MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';

type Props = {
  submitting: boolean;
  // mainSelect: string;
  subSelectedList: SelectType[];
  setSubSelectedList: Dispatch<SetStateAction<SelectType[]>>;
  setMainSelectResetFlg: Dispatch<SetStateAction<boolean>>;
  setSubSelectResetFlg: Dispatch<SetStateAction<boolean>>;
  mainSelectResetFlg: boolean;
  subSelectResetFlg: boolean;
  subSelectDel: (deleteId: string) => void;
  subSelectReset?: () => void;
  isBelongChange?: boolean;
  isSubRefDelete?: boolean;
};

export const OtherBelongFormSelectGroup: React.FC<Props> = ({
  submitting,
  subSelectedList,
  setSubSelectedList,
  setMainSelectResetFlg,
  setSubSelectResetFlg,
  mainSelectResetFlg,
  subSelectResetFlg,
  subSelectDel,
  isBelongChange = true,
  isSubRefDelete = true,
}) => {
  const { pathname } = useLocation();
  const params = useUrlParams();
  const user = useCurrentUser();
  const paramAllClient = useParamClientAll();
  const [isLoading, setIsLoading] = useState(false);
  const [tobAccountSubRefList, setTobAccountSubRefList] = useState<TobAccountSubRefListOutputResponse>();
  const accountId = params.accountUpdateId === '' ? undefined : params.accountUpdateId;
  const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
  // 選択した所属をatomで管理（サブ所属で使用）
  const [mainSelect, setMainSelect] = useRecoilState(mainSelectedBelongState(strAccountId));
  // サブで選択した権限
  const { mainEditFlg, setMainEditFlg } = useContext(MainBelongFlgContext);
  const render = accountId !== undefined && accountId.length > 0;
  const fancrewAdminClientFlg = MASTER_CLIENT.clientId.some((v) => v === Number(paramAllClient));
  let data: TobAccountSubRefListOutputResponse;
  const api = new AccountApi();
  const [modalProps, setModalProps] = useState<ModalProps | undefined>();

  const handleTobAccountSubRefList = async () => {
    try {
      const res = await api.tobAccountSubRefList(accountId, paramAllClient);
      setTobAccountSubRefList(res.data);
    } catch (e) {
      console.error('Failed to fetch tobAccountSubRefList:', e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true; // マウント状態をトラック
    if (isMounted) {
      // マウントされている場合のみステートを更新
      setIsLoading(true);
      if (render) {
        handleTobAccountSubRefList();
      }
    }
    return () => {
      isMounted = false; // クリーンアップでアンマウントを記録
    };
  }, []);
  useEffect(() => {
    let isMounted = true; // マウント状態をトラック
    if (isMounted) {
      // マウントされている場合のみステートを更新
      setIsLoading(true);
      if (render) {
        handleTobAccountSubRefList();
      }
    }
    return () => {
      isMounted = false; // クリーンアップでアンマウントを記録
    };
  }, [accountId, paramAllClient]);

  const handleClick = async () => {
    const ret = await new Promise<string>((resolve) => {
      setModalProps({
        returnValue: resolve,
        setSubSelectedList,
        subSelectedList,
        setMainSelectResetFlg,
        setSubSelectResetFlg,
        mainSelectResetFlg,
        subSelectResetFlg,
        userId: accountId!,
      });
    });
    setModalProps(undefined);
  };
  const deleteClick = async (deleteId: string) => {
    try {
      const response: AxiosResponse<Array<ResultOutputResponse>> = await api.tobAccountSubRefDel(
        paramAllClient,
        deleteId
      );
      // APIが成功した場合にのみ以下の処理を実行
      const result = subSelectedList.filter((item) => item.subRefId !== Number(deleteId));
      // 削除後に再度リストを取得し直し
      await handleTobAccountSubRefList();
      // 削除項目を削除したものをセット
      setSubSelectedList(result);
    } catch (error) {
      console.error('Error deleting subRef:', error);
    }
  };

  const Wrap = () => {
    if (!isLoading && tobAccountSubRefList) {
      data = tobAccountSubRefList;
      const subList = [...subSelectedList];
      data.client.map((client) => {
        const cret = subList.find((element) => {
          return element.id === client.clientId.toString() && mainSelect !== client.clientId.toString();
        });
        if (!cret) {
          subList.push({ id: client.clientId.toString(), value: client.clientName, subRefId: client.subRefId });
        }
      });
      data.organization.map((org) => {
        const oret = subList.find((element) => {
          return element.id === org.organizationId.toString() && mainSelect !== org.organizationId.toString();
        });
        if (!oret) {
          subList.push({ id: org.organizationId.toString(), value: org.organizationName, subRefId: org.subRefId });
        }
      });
      data.contractShop.map((contractShop) => {
        const csret = subList.find((element) => {
          return (
            element.id === contractShop.contractShopId.toString() &&
            mainSelect !== contractShop.contractShopId.toString()
          );
        });
        if (!csret) {
          subList.push({
            id: contractShop.contractShopId.toString(),
            value: contractShop.contractShopName,
            subRefId: contractShop.subRefId,
          });
        }
      });
      // 更新が必要な場合のみステートを更新
      if (JSON.stringify(subSelectedList) !== JSON.stringify(subList)) {
        setSubSelectedList(subList);
      }
      return (
        <>
          {subSelectedList.length !== 0 && (
            <div>
              <label className="fw-bold mt-2 d-block">その他の所属・グループ</label>
              <ul>
                {subSelectedList.map((sub) => (
                  <li className="list-unstyled d-flex justify-content-start align-items-center" key={sub.id}>
                    <div className="me-2">{sub.value}</div>{' '}
                    {isSubRefDelete && (
                      <TiDelete className={scss.delete_icon} onClick={() => deleteClick(sub.subRefId!.toString())} />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      );
    }
    return <></>;
  };
  return (
    <>
      {isBelongChange && !user.belongCode.includes('S') && (
        <>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={handleClick} className={scss.sub_belong_button} disabled={mainEditFlg}>
              その他の所属・グループを追加する
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <span className={scss.sub_belong_warning}>
              ※この画面で所属を変更した場合、追加していた「その他の所属・グループ」は設定が解除されます。
            </span>
          </div>
        </>
      )}
      <div className="d-flex justify-content-start">{render ? <Wrap /> : <></>}</div>
      {modalProps && <SubBelongModal2 {...modalProps} />}
    </>
  );
};
