import React, { memo, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountApi, TobAccountInfoOutputResponse } from '../../api-client';
import scss from '../../scss/organisms/registerUser.module.scss';
import layoutScss from '../../scss/templates/mainLayout.module.scss';
import { TITLE } from '../../constants/Title';
import { Title } from '../atoms/Title';
import { RegistUserForm } from '../organisms/Form/RegistUserForm';
import { EditAccount } from '../organisms/UserManagement/EditAccount';
import { SpinLoading } from '../molecules/Loading/Loading';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useParamClientAll } from '../../hooks/useParamClientAll';
import { Url } from '../../constants/Url';
import { urlParamConv } from '../../utils/functions';

const MyEditUserPage: React.FC = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [tobMyAccountInfo, setTobMyAccountInfo] = useState<TobAccountInfoOutputResponse>();
  const paramAllClient = useParamClientAll();
  const { accountId, clientOrgShopId } = useCurrentUser();
  const render = accountId > 0 && clientOrgShopId.length > 0;
  const history = useHistory();
  const { search } = useLocation();
  const location = useLocation();

  const handleTobMyAccountInfo = async () => {
    const api = new AccountApi();
    try {
      const res = await api.tobAccountInfo(accountId.toString(), clientOrgShopId, paramAllClient, 'true');
      setTobMyAccountInfo(res.data);
    } catch (error) {
      console.error('Failed to fetch tobAccountInfo:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // 初期表示時にURLパラメータを付与
    if (render) {
      const temp = urlParamConv(search, 'accountUpdateId', accountId.toString());
      history.push({
        pathname: Url.EDIT_ACCOUNT,
        search: urlParamConv(temp, 'accountUpdateClientOrgShopId', clientOrgShopId),
      });
    }
  }, [render, history]);

  useEffect(() => {
    setIsLoading(true);
    if (render) {
      handleTobMyAccountInfo();
    }
  }, [render, location, paramAllClient]);

  const Wrap = () => {
    if (isLoading) {
      return (
        <>
          <div className={`d-flex justify-content-center align-items-center ${scss.spiner_wrapper}`}>
            <SpinLoading />
          </div>
        </>
      );
    }
    if (!isLoading && tobMyAccountInfo && tobMyAccountInfo.account) {
      return <EditAccount myAccount={tobMyAccountInfo.account} />;
    }
    return (
      <>
        <div className="h5 font-bold p-4">データが存在しませんでした。</div>
      </>
    );
  };

  return (
    <>
      <Container fluid className={scss.form_group}>
        <div>
          <Title className="my-3">{TITLE.TOB.ACCOUNT_EDIT}</Title>
        </div>
      </Container>
      <Container
        fluid
        className={`${scss.result_group} ${layoutScss.narrow} ${scss.content_under_margin}`}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Wrap />
      </Container>
    </>
  );
});

export default MyEditUserPage;
