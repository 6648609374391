import React, { memo } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { IconContext } from 'react-icons';
import {
  FaChartLine,
  FaClipboardCheck,
  FaClipboardList,
  FaComment,
  FaFileContract,
  FaFileDownload,
  FaFileInvoice,
  FaFlag,
  FaGift,
  FaHourglassHalf,
  FaLayerGroup,
  FaListUl,
  FaLock,
  FaNewspaper,
  FaRandom,
  FaRegAddressCard,
  FaRegBuilding,
  FaRegChartBar,
  FaRegClipboard,
  FaRegCommentDots,
  FaRegEdit,
  FaRegImages,
  FaRegListAlt,
  FaSignInAlt,
  FaSignOutAlt,
  FaTachometerAlt,
  FaTrophy,
  FaUndoAlt,
  FaUserCircle,
  FaUsers,
  FaUsersCog,
  FaVideo,
  FaAddressCard,
} from 'react-icons/fa';
import scss from '../../scss/templates/sidebarlayout.module.scss';
import { Url } from '../../constants/Url';
import { openModal } from '../../states/atom/InquiryModalOpen';
import { TITLE } from '../../constants/Title';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useSelectStack } from '../../hooks/useSelectStack';
import { useNewCloud } from '../../hooks/useNewCloud';
import { sidebarState } from '../../states/atom/CommonPageState';
import { useCategoryId } from '../../hooks/useCategoryId';

export const SideBarLayout: React.FC = memo(() => {
  const { clientAdminFlg, userEditFlg, goalSetFlg, giftViewFlg, isBelongClient, isBelongOrg, isBelongShop } =
    useCurrentUser();
  const { selectMode, stackMode } = useSelectStack();
  const { isBeauty } = useCategoryId();
  const { newMode, cloudMode } = useNewCloud();
  const { pathname, search } = useLocation();
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  const history = useHistory();
  const handleTransition = (url: string) => {
    history.push({
      pathname: url,
      search,
    });
  };
  const handleContact = () => {
    setSidebar(false);
    setOpen(true);
  };

  const handleLogout = async (url: string) => {
    await history.push(Url.LOGOUT);
  };

  // お問合せモーダル
  const [open, setOpen] = useRecoilState(openModal);

  return (
    <div className={`${scss.wrapper} bg-light`}>
      <aside>
        <nav className={`${scss.nav_sidebar}`}>
          <div className={`${scss.sidebar} `}>
            <ListGroup.Item className={`${scss.subtitle}`}>お店の状態をみる</ListGroup.Item>
            <ListGroup className="nav flex-column">
              {/* <ListGroup.Item action onClick={() => handleTransition(Url.CLIENT_SELECT)}> */}
              {/*  <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*    <FaUserCircle /> */}
              {/*    <span>{TITLE.TOB.CLIENT_SELECT}</span> */}
              {/*  </IconContext.Provider> */}
              {/* </ListGroup.Item> */}
              {newMode && (
                <ListGroup.Item
                  action
                  className={pathname === Url.SCORE_VIEW ? scss.top : ''}
                  onClick={() => handleTransition(Url.SCORE_VIEW)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaChartLine />
                    <span>{TITLE.TOB.SCORE_VIEW}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              {cloudMode && (
                <ListGroup.Item
                  action
                  className={pathname === Url.CS_SCORE ? scss.top : ''}
                  onClick={() => handleTransition(Url.CS_SCORE)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaTachometerAlt />
                    <span>{TITLE.TOB.CS_SCORE}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item
                action
                className={pathname === Url.SCORE_SHOP_BY_MONTH ? scss.top : ''}
                onClick={() => handleTransition(Url.SCORE_SHOP_BY_MONTH)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaRegChartBar />
                  <span>{TITLE.TOB.SCORE_SHOP_BY_MONTH}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              <ListGroup.Item
                action
                className={pathname === Url.ANSWER_DISTRIBUTION ? scss.top : ''}
                onClick={() => handleTransition(Url.ANSWER_DISTRIBUTION)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaRandom />
                  <span>{TITLE.TOB.ANSEWR_DISTRIBUTION}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              <ListGroup.Item
                action
                className={pathname === Url.IMPROVE_QUESTION ? scss.top : ''}
                onClick={() => handleTransition(Url.IMPROVE_QUESTION)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaClipboardList />
                  <span>{TITLE.TOB.IMPROVE_QUESTION}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              <ListGroup.Item
                action
                className={pathname === Url.COMMENT_LIST ? scss.top : ''}
                onClick={() => handleTransition(Url.COMMENT_LIST)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaComment />
                  <span>{TITLE.TOB.COMMENT_LIST}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              {!isBeauty && (
                <ListGroup.Item
                  action
                  className={pathname === Url.STAFF_COMMENT_LIST ? scss.top : ''}
                  onClick={() => handleTransition(Url.STAFF_COMMENT_LIST)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaUsers />
                    <span>{TITLE.TOB.STAFF_COMMENT_LIST}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item
                action
                className={`${pathname === Url.ENQUETE ? scss.top : ''}`}
                onClick={() => handleTransition(Url.ENQUETE)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaRegEdit />
                  <span>{TITLE.TOB.ENQUETE}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              {!isBeauty && (
                <ListGroup.Item
                  action
                  className={pathname === Url.IMAGE_VIEW ? scss.top : ''}
                  onClick={() => handleTransition(Url.IMAGE_VIEW)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaRegImages />
                    <span>{TITLE.TOB.IMAGE_VIEW}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item
                action
                className={pathname === Url.MONITOR_PROGRESS ? scss.top : ''}
                onClick={() => handleTransition(Url.MONITOR_PROGRESS)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaHourglassHalf />
                  <span>{TITLE.TOB.MONITOR_PROGRESS}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              <ListGroup.Item className={scss.subtitle}>お店を改善する</ListGroup.Item>

              {isBelongClient && (
                <>
                  {goalSetFlg && (
                    <ListGroup.Item
                      action
                      className={pathname === Url.SHOP_CATEGORY_GOAL_SETTING ? scss.top : ''}
                      onClick={() => handleTransition(Url.SHOP_CATEGORY_GOAL_SETTING)}
                    >
                      <IconContext.Provider value={{ size: '1.2rem' }}>
                        <FaFlag />
                        <span>{TITLE.TOB.SHOP_CATEGORY_GOAL_SETTING}</span>
                      </IconContext.Provider>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item
                    action
                    className={pathname === Url.SHOP_CATEGORY_GOAL_RESULT ? scss.top : ''}
                    onClick={() => handleTransition(Url.SHOP_CATEGORY_GOAL_RESULT)}
                  >
                    <IconContext.Provider value={{ size: '1.2rem' }}>
                      <FaRegListAlt />
                      <span>{TITLE.TOB.SHOP_CATEGORY_GOAL_RESULT}</span>
                    </IconContext.Provider>
                  </ListGroup.Item>
                </>
              )}

              {(isBelongClient || isBelongOrg || isBelongShop) && (
                <>
                  {goalSetFlg && (
                    <ListGroup.Item
                      action
                      className={`${pathname === Url.SHOP_GOAL_SETTING ? scss.top : ''}`}
                      onClick={() => handleTransition(Url.SHOP_GOAL_SETTING)}
                    >
                      <IconContext.Provider value={{ size: '1.2rem' }}>
                        <FaFlag />
                        <span>{TITLE.TOB.SHOP_GOAL_SETTING}</span>
                      </IconContext.Provider>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item
                    action
                    className={`${pathname === Url.SHOP_GOAL_RESULT ? scss.top : ''}`}
                    onClick={() => handleTransition(Url.SHOP_GOAL_RESULT)}
                  >
                    <IconContext.Provider value={{ size: '1.2rem' }}>
                      <FaRegListAlt />
                      <span>{TITLE.TOB.SHOP_GOAL_RESULT}</span>
                    </IconContext.Provider>
                  </ListGroup.Item>
                </>
              )}

              <ListGroup.Item
                action
                className={pathname === Url.LIBRARY ? scss.top : ''}
                onClick={() => handleTransition(Url.LIBRARY)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaVideo />
                  <span>{TITLE.TOB.LIBRARY}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              <ListGroup.Item className={scss.subtitle}>ランキングをみる</ListGroup.Item>
              <ListGroup.Item
                action
                className={pathname === Url.CLIENT_IN_SHOP_RANKING ? scss.top : ''}
                onClick={() => handleTransition(Url.CLIENT_IN_SHOP_RANKING)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaRegBuilding />
                  <span>{TITLE.TOB.CLIENT_IN_SHOP}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              {selectMode && (
                <ListGroup.Item
                  action
                  className={pathname === Url.NATIONAL_SHOP_RANKING ? scss.top : ''}
                  onClick={() => handleTransition(Url.NATIONAL_SHOP_RANKING)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaTrophy />
                    <span>{TITLE.TOB.NATIONAL_RANKING}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item className={scss.subtitle}>その他</ListGroup.Item>
              {/* <ListGroup.Item */}
              {/*  action */}
              {/*  className={pathname === Url.MY_PAGE ? scss.top : ''} */}
              {/*  onClick={() => handleTransition(Url.MY_PAGE)} */}
              {/* > */}
              {/*  <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*    <FaRegAddressCard /> */}
              {/*    <span>{TITLE.TOB.MY}</span> */}
              {/*  </IconContext.Provider> */}
              {/* </ListGroup.Item> */}
              {clientAdminFlg && (
                <ListGroup.Item
                  action
                  className={`${pathname === Url.CONFIRM_CONTRACT ? scss.top : ''}`}
                  onClick={() => handleTransition(Url.CONFIRM_CONTRACT)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaFileContract />
                    <span>{TITLE.TOB.CONFIRM_CONTRACT}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item
                action
                className={pathname === Url.ENQUETE_DESIGN ? scss.top : ''}
                onClick={() => handleTransition(Url.ENQUETE_DESIGN)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaClipboardCheck />
                  <span>{TITLE.TOB.ENQUETE_CONTENT}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              {clientAdminFlg && (
                <ListGroup.Item
                  action
                  className={`${pathname === Url.INVOICE_LIST ? scss.top : ''} ${scss.disabled}`}
                  style={{ backgroundColor: '#333c44' }}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaFileInvoice />
                    <span>{TITLE.TOB.INVOICE_LIST}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              {userEditFlg && (
                <ListGroup.Item
                  action
                  className={pathname === Url.USER_LIST ? scss.top : ''}
                  onClick={() => handleTransition(Url.USER_LIST)}
                >
                  <IconContext.Provider value={{ size: '1.2rem' }}>
                    <FaUsersCog />
                    <span>{TITLE.TOB.USER_LIST}</span>
                  </IconContext.Provider>
                </ListGroup.Item>
              )}
              <ListGroup.Item
                action
                className={pathname === Url.EDIT_ACCOUNT ? scss.top : ''}
                onClick={() => handleTransition(Url.EDIT_ACCOUNT)}
              >
                <IconContext.Provider value={{ size: '1.2rem' }}>
                  <FaAddressCard />
                  <span>{TITLE.TOB.ACCOUNT_EDIT}</span>
                </IconContext.Provider>
              </ListGroup.Item>
              {/*  <ListGroup.Item */}
              {/*    action */}
              {/*    className={pathname === Url.INQUIRY_LIST ? scss.top : ''} */}
              {/*    onClick={() => handleTransition(Url.INQUIRY_LIST)} */}
              {/*  > */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaListUl /> */}
              {/*      <span>{TITLE.TOB.INQUIRY_LIST} ※ROI側画面</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleTransition('')}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaFileDownload /> */}
              {/*      <span>分析用データをダウンロードする</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleTransition('')}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaRegClipboard /> */}
              {/*      <span>当月の結果をレポートする（本部向け）：YYYY年MM月モニターレポート</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleTransition('')}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaLayerGroup /> */}
              {/*      <span>組織グループを登録する</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleTransition('')}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaRegClipboard /> */}
              {/*      <span> 当月の結果をレポートする（メーカー向け）</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/* </ListGroup> */}
              {/* <ListGroup className="mb-4"> */}
              {/*  <ListGroup.Item action onClick={() => handleLogout(Url.LOGIN)}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaSignInAlt /> */}
              {/*      <span> ログイン画面</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleLogout(Url.PASSWORD_FORGET)}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaUndoAlt /> */}
              {/*      <span> パスワードリセット画面</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
              {/*  <ListGroup.Item action onClick={() => handleLogout(Url.PASSWORD_RESET_MAIL_COMPLETE)}> */}
              {/*    <IconContext.Provider value={{ size: '1.2rem' }}> */}
              {/*      <FaLock /> */}
              {/*      <span> パスワードリセット依頼完了画面</span> */}
              {/*    </IconContext.Provider> */}
              {/*  </ListGroup.Item> */}
            </ListGroup>
            {/* <button type="button" className={scss.event_button} onClick={() => handleTransition(Url.NEWS_LIST)}> */}
            {/*  <IconContext.Provider value={{ size: '1.2rem' }}> */}
            {/*    <FaNewspaper /> */}
            {/*    <span> お知らせ画面</span> */}
            {/*  </IconContext.Provider> */}
            {/* </button> */}
            <button type="button" className={scss.event_button} onClick={() => handleContact()}>
              <IconContext.Provider value={{ size: '1.2rem' }}>
                <FaRegCommentDots />
                <span> 問合せをする（入力フォーム）</span>
              </IconContext.Provider>
            </button>
            <button type="button" className={`${scss.event_button} mb-1`} onClick={() => handleLogout(pathname)}>
              <IconContext.Provider value={{ size: '1.2rem' }}>
                <FaSignOutAlt />
                <span> ログアウト</span>
              </IconContext.Provider>
            </button>
          </div>
        </nav>
      </aside>
    </div>
  );
});
