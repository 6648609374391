import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { FormControl } from 'react-bootstrap';
import FormRadio from '../../atoms/Form/FormRadio';
import {
  belongDataListState,
  mainBelongEditFlgState,
  mainSelectedAuthState,
  subSelectedAuthState,
} from '../../../states/atom/RegisterFormState';
import { subBelongHierarchyLevelSelector } from '../../../states/selector/SubBelongSelectSelector';
import { TobAccountSubHierarchyRowDataResponse } from '../../../api-client';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { SharedRadioParamContext } from '../../../hooks/useNewRadio';
import { MainBelongFlgContext } from '../../../hooks/useMainBelongFlg';

type BelongProps = {
  label: string;
  value: string;
};
export const SubBelongRadioGroup: React.FC = memo(() => {
  const { pathname } = useLocation();
  const params = useUrlParams();
  const strAccountId: string | undefined = params.accountUpdateId === '' ? '' : `${params.accountUpdateId}`;
  const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
  // Client,Organization,ContractShop情報を格納
  const belongData = useRecoilValue(belongDataListState(strAccountId));
  // サブで選択した権限
  const [subAuth, setSubAuth] = useRecoilState(subSelectedAuthState(strAccountId));
  // サブ所属の一覧（階層ラジオボタン）
  const [belongList, setBelongList] = useState<BelongProps[]>();
  // メイン所属変更したFlg
  const { mainEditFlg, setMainEditFlg } = useContext(MainBelongFlgContext);

  const handleChange = useCallback(
    (e: React.ChangeEvent<typeof FormControl & HTMLInputElement>) => {
      setSubAuth(e.target.value);
      setMainEditFlg(false);
    },
    [setSubAuth]
  );

  useEffect(() => {
    const hierarchyData: BelongProps[] = [];
    if (mainAuth === '3') {
      setBelongList([{ label: '店舗', value: '3' }]);
    } else {
      let workHierarchyLevel: number;
      // 組織から
      if (mainAuth.includes('H')) {
        belongData!.hierachyLevel.map((hie: TobAccountSubHierarchyRowDataResponse, i) => {
          // 階層IDが同じなら表示
          // eslint-disable-next-line no-nested-ternary
          if (hie.hierarchyId === mainAuth) {
            workHierarchyLevel = hie.hierarchyLevel!;
          }
        });
      }
      belongData!.hierachyLevel.map((h) => {
        if (workHierarchyLevel <= h!.hierarchyLevel!) {
          hierarchyData.push({ label: h.hierarchyName!.toString(), value: h.hierarchyId!.toString() });
        }
      });
      setBelongList([...hierarchyData, { label: '店舗', value: '3' }]);
    }
    //   const belong = defaultValues?.belongCode;
    //   if (belong !== undefined && belong !== '') {
    //     const split = belong.split(',');
    //     let status = {
    //       C: '1',
    //       O: '2',
    //       S: '3',
    //     }[split[0]];
    //     // 組織の場合はStatusに組織IDを設定する
    //     if (status === '2') {
    //       data.organization.map((org) => {
    //         if (org.organizationId === belong) {
    //           // eslint-disable-next-line prefer-destructuring
    //           status = org.hierarchyId;
    //         }
    //       });
    //     }
    //     setMainAuth(status!);
    //   }
  }, []);

  //
  // if (belongList != null) {
  //   belongList.map((data) => radioData.push({ label: data.hierarchyId!, value: data.hierarchyName! }));
  // }
  // radioData.push({ label: '店舗', value: '3' });

  return (
    <div className="mt-2 mb-3 d-flex align-items-center">
      {belongList &&
        belongList.map((o) => (
          <FormRadio
            label={o.label}
            value={o.value}
            handleChange={handleChange}
            isAuth={subAuth}
            key={o.label}
            id="sub-create"
            disabled={false} // TODO
          />
        ))}
    </div>
  );
});
