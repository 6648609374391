import { selectorFamily } from 'recoil';
import { AxiosResponse } from 'axios';
import { RecoilSelectorKeys } from '../RecoilKeys';
import { mainSelectedBelongState, subBelongListState } from '../atom/RegisterFormState';
import {
  AccountApi,
  TobAccountSubContractShopRowDataResponse,
  TobAccountSubHierarchyRowDataResponse,
  TobAccountSubListOutputResponse,
  TobAccountSubOrganizationRowDataResponse,
} from '../../api-client';

export const subBelongHierarchyLevelSelector = selectorFamily<
  TobAccountSubHierarchyRowDataResponse[] | null | undefined,
  string
>({
  key: RecoilSelectorKeys.SUB_BELONG_HIERARCHY_RADIO_SELECTOR,
  get(pathname: string) {
    return async ({ get }) => {
      // サブ所属のデータ一覧
      let subData: TobAccountSubListOutputResponse | null = get(subBelongListState(pathname));
      if (subData) {
        const accountApi = new AccountApi();
        const response = accountApi.tobAccountSubListOutput();
        subData = (await response).data;
      }
      if (subData === undefined) {
        return null;
      }
      return subData?.hierachyLevel;
    };
  },
});

export const subBelongGroupSelectSelector = selectorFamily<TobAccountSubListOutputResponse, string>({
  key: RecoilSelectorKeys.SUB_BELONG_GROUP_SELECT_SELECTOR,
  get: (id: string) => {
    return async ({ get }) => {
      const idArray = id.split(':');
      let tobAccountId: string | undefined;
      let allClientId: string | undefined;
      if (idArray.length > 1 || idArray[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        tobAccountId = idArray[1];
      }
      if (idArray.length > 2 || idArray[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        allClientId = idArray[2];
      }
      // サブ所属のデータ一覧
      let subData: TobAccountSubListOutputResponse | null = get(subBelongListState(id));
      const accountApi = new AccountApi();
      const response = accountApi.tobAccountSubListOutput(tobAccountId, idArray[0], allClientId);
      subData = (await response).data;

      // メインで選択した所属
      const select = get(mainSelectedBelongState(id));
      //
      const list: TobAccountSubListOutputResponse | null = {
        client: [],
        hierachyLevel: [],
        organization: [],
        contractShop: [],
      };
      // 契約店舗一覧
      const contractShopResult = subData?.contractShop;
      if (subData?.organization === undefined) {
        return list;
      }
      // 組織一覧
      list.organization = subData?.organization.filter((org: TobAccountSubOrganizationRowDataResponse) => {
        if (org.organizationId !== select) {
          return org;
        }
      });
      if (contractShopResult === undefined) {
        return list;
      }
      list.contractShop = contractShopResult;

      return list;
    };
  },
});

export const subBelongShopSelectSelector = selectorFamily<TobAccountSubListOutputResponse, string>({
  key: RecoilSelectorKeys.SUB_BELONG_SHOP_SELECT_SELECTOR,
  get:
    (id: string) =>
    async ({ get }) => {
      const idArray = id.split(':');
      let tobAccountId: string | undefined;
      if (idArray.length > 1 || idArray[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        tobAccountId = idArray[1];
      }
      // サブ所属のデータ一覧
      let subData: TobAccountSubListOutputResponse | null = get(subBelongListState(id));
      // if (!subData || (subData.client.length === 0 && subData.organization.length === 0 && subData.contractShop.length === 0)) {
      const accountApi = new AccountApi();
      const response = accountApi.tobAccountSubListOutput(tobAccountId, idArray[0]);
      response.then((res) => {
        subData = res.data;
      });
      // }
      // メインで選択した所属
      // const select = get(mainSelectedBelongState(pathname));
      const select = id;
      //
      const list: TobAccountSubListOutputResponse | null = {
        client: [],
        hierachyLevel: [],
        organization: [],
        contractShop: [],
      };
      if (subData?.contractShop === undefined) {
        return list;
      }
      // 契約店舗一覧
      list.contractShop = subData?.contractShop.filter((contractShop: TobAccountSubContractShopRowDataResponse) => {
        if (contractShop.contractShopId !== select) {
          return contractShop;
        }
      });
      return list;
    },
});
