import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import scss from '../../../scss/organisms/registerUser.module.scss';
import { mainSelectedAuthState, sendMailState } from '../../../states/atom/RegisterFormState';
import {
  TobAccountCreateFormResponse,
  TobAccountUpdateFormResponse,
  TobAccountInfoOutputRowDataResponse,
} from '../../../api-client';
import { ToolTip } from '../../atoms/Tooltip';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useUrlParams } from '../../../hooks/useUrlParams';
import { Url } from '../../../constants/Url';
import { SharedRadioParamContext } from '../../../hooks/useNewRadio';
import { Common } from '../../../constants/Common';

export type Props = {
  register: UseFormRegister<TobAccountCreateFormResponse>;
  setValue: UseFormSetValue<TobAccountCreateFormResponse>;
  getValues: UseFormGetValues<TobAccountCreateFormResponse>;
  submitting: boolean;
  roiAdminDisplayFlg: boolean;
  noChange?: boolean;
  myAccount?: TobAccountInfoOutputRowDataResponse;
};

export const AuthCheckFormGroup: React.FC<Props> = ({
  register,
  getValues,
  setValue,
  submitting,
  roiAdminDisplayFlg,
  noChange = false,
  myAccount,
}) => {
  const { clientAdminFlg } = useCurrentUser();
  const { pathname } = useLocation();
  const params = useUrlParams();
  const numAccountId = params.accountUpdateId === '' ? '' : params.accountUpdateId;
  const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
  const [viewMainAuth, setViewMainAuth] = useState(mainAuth);
  const setSendMailFlg = useSetRecoilState(sendMailState(numAccountId));
  const [disable, setDisable] = useState(false);

  // 各項目単位のチェックボックス制御
  const [ctrlClientAdminFlg, setCtrlClientAdminFlg] = useState(false);
  const [ctrlRoiAdminFlg, setCtrlRoiAdminFlg] = useState(false);
  const [ctrlUserEditFlg, setCtrlUserEditFlg] = useState(false);
  const [ctrlGoalSetFlg, setCtrlGoalSetFlg] = useState(false);
  const [ctrlViewAllFlg, setCtrlViewAllFlg] = useState(false);

  // 非活性制御
  const isClientAdminFlgDisable = noChange || ctrlClientAdminFlg;
  const isRoiAdminFlgDisable = submitting || noChange || ctrlRoiAdminFlg;
  const isUserEditFlgDisable = noChange || submitting || disable || ctrlUserEditFlg;
  const isGoalSetFlgDisable = noChange || submitting || disable || ctrlGoalSetFlg;
  const isViewAllFlgDisable = noChange || submitting || disable || ctrlViewAllFlg;
  const isMailSendFlgDisable = submitting;

  const handleSetAuth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setDisable(true);
        setValue('userEditFlg', true);
        setValue('goalSetFlg', true);
        setValue('viewAllFlg', true);
      } else {
        setValue('userEditFlg', false);
        setValue('goalSetFlg', false);
        setValue('viewAllFlg', false);
        setDisable(false);
      }
    },
    [setDisable, setValue]
  );

  const handleChangeMailFlg = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSendMailFlg(true);
      } else {
        setSendMailFlg(false);
      }
    },
    [setSendMailFlg]
  );

  useEffect(() => {
    // 管理者だった場合
    if (getValues('clientAdminFlg')) {
      setDisable(true);
    }
    if (myAccount) {
      if (!myAccount.clientAdminFlg) {
        setCtrlClientAdminFlg(true);
      }
      if (!myAccount.roiAdminFlg) {
        setCtrlRoiAdminFlg(true);
      }
      if (!myAccount.userEditFlg) {
        setCtrlUserEditFlg(true);
      }
      if (!myAccount.goalSetFlg) {
        setCtrlGoalSetFlg(true);
      }
      if (!myAccount.viewAllFlg) {
        setCtrlViewAllFlg(true);
      }
    }
  }, []);

  return (
    <div className={scss.check_flex}>
      {(mainAuth === Common.beLong.mainAuth.clientMainAuth ||
        (pathname === Url.REGISTER_USER && mainAuth === '' && clientAdminFlg) ||
        pathname === Url.EDIT_ACCOUNT) && (
        <ToolTip
          content="チェックを付けることで、管理者権限を付与することができます。この権限は本部に所属しているユーザにしか付与することができません。"
          isToolTipDisplay={!isClientAdminFlgDisable}
        >
          <Form.Check
            type="checkbox"
            {...register('clientAdminFlg')}
            id="client"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetAuth(e)}
            className={`${scss.checkbox_wrapper}`}
            label="管理者"
            disabled={isClientAdminFlgDisable}
          />
        </ToolTip>
      )}
      {roiAdminDisplayFlg && (
        <ToolTip
          content="チェックを付けることで、ROI社内管理者権限を付与することができます。"
          isToolTipDisplay={!isRoiAdminFlgDisable}
        >
          <Form.Check
            type="checkbox"
            {...register('roiAdminFlg')}
            id="roi"
            className={`${scss.checkbox_wrapper}`}
            disabled={isRoiAdminFlgDisable}
            label="ROI管理者権限"
          />
        </ToolTip>
      )}
      <ToolTip content="チェックを付けるとユーザ設定権限が付与されます。" isToolTipDisplay={!isUserEditFlgDisable}>
        <Form.Check
          type="checkbox"
          {...register('userEditFlg')}
          id="user"
          className={`${scss.checkbox_wrapper}`}
          disabled={isUserEditFlgDisable}
          label="所属ユーザ編集権限"
        />
      </ToolTip>
      <ToolTip content="チェックを付けると目標設定権限が付与されます。" isToolTipDisplay={!isGoalSetFlgDisable}>
        <Form.Check
          type="checkbox"
          {...register('goalSetFlg')}
          id="goal"
          className={`${scss.checkbox_wrapper}`}
          disabled={isGoalSetFlgDisable}
          label="目標設定権限"
        />
      </ToolTip>
      <ToolTip content="チェックを付けると全店参照権限が付与されます。" isToolTipDisplay={!isViewAllFlgDisable}>
        <Form.Check
          type="checkbox"
          {...register('viewAllFlg')}
          id="viewall"
          disabled={isViewAllFlgDisable}
          className={`${scss.checkbox_wrapper}`}
          label="全店舗参照権限"
        />
      </ToolTip>
      <ToolTip content="チェックを付けるとメールが配信されます。" isToolTipDisplay={!isMailSendFlgDisable}>
        <Form.Check
          type="checkbox"
          {...register('mailSendFlg')}
          id="mail"
          className={`${scss.checkbox_wrapper}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMailFlg(e)}
          disabled={isMailSendFlgDisable}
          label="メール受信設定"
        />
      </ToolTip>
    </div>
  );
};

export type UpdateProps = {
  register: UseFormRegister<TobAccountUpdateFormResponse>;
  setValue: UseFormSetValue<TobAccountUpdateFormResponse>;
  getValues: UseFormGetValues<TobAccountUpdateFormResponse>;
};

export const UpdateAuthCheckFormGroup: React.FC<UpdateProps> = memo(({ register, setValue, getValues }) => {
  const params = useUrlParams();
  const numAccountId = params.accountUpdateId === '' ? '' : params.accountUpdateId;
  const { mainAuth, setMainAuth } = useContext(SharedRadioParamContext);
  const setSendMailFlg = useSetRecoilState(sendMailState(numAccountId));
  const [disable, setDisable] = useState(false);

  const handleSetAuth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setDisable(true);
        setValue('userEditFlg', true);
        setValue('goalSetFlg', true);
      } else {
        setDisable(false);
      }
    },
    [setDisable, setValue]
  );

  const handleChangeMailFlg = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSendMailFlg(true);
      } else {
        setSendMailFlg(false);
      }
    },
    [setSendMailFlg]
  );

  return (
    <div className={scss.check_flex}>
      {mainAuth === '1' && (
        <>
          <ToolTip content="チェックを付けることで、管理者権限を付与することができます。この権限は本部に所属しているユーザにしか付与することができません。">
            <label htmlFor="client">
              <input
                type="checkbox"
                {...register('clientAdminFlg')}
                id="client"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetAuth(e)}
                className="me-2"
              />
              管理者
            </label>
          </ToolTip>
        </>
      )}
      {mainAuth === '99' && (
        <>
          <ToolTip content="チェックを付けることで、ROI社内管理者権限を付与することができます。">
            <label htmlFor="roi">
              <input type="checkbox" {...register('roiAdminFlg')} id="roi" className="me-2" />
              ROI管理者権限
            </label>
          </ToolTip>
        </>
      )}
      {disable ? (
        <>
          <ToolTip content="チェックを付けるとユーザ設定権限が付与されます。">
            <label htmlFor="user">
              <input type="checkbox" {...register('userEditFlg')} id="user" disabled className="me-2" />
              所属ユーザ編集権限
            </label>
          </ToolTip>
          <ToolTip content="チェックを付けると目標設定権限が付与されます。">
            <label htmlFor="goal">
              <input type="checkbox" {...register('goalSetFlg')} id="goal" disabled className="me-2" />
              目標設定権限
            </label>
          </ToolTip>
        </>
      ) : (
        <>
          <ToolTip content="チェックを付けるとユーザ設定権限が付与されます。">
            <label htmlFor="user">
              <input type="checkbox" {...register('userEditFlg')} id="user" className="me-2" />
              所属ユーザ編集権限
            </label>
          </ToolTip>
          <ToolTip content="チェックを付けると目標設定権限が付与されます。">
            <label htmlFor="goal">
              <input type="checkbox" {...register('goalSetFlg')} id="goal" className="me-2" />
              目標設定権限
            </label>
          </ToolTip>
        </>
      )}
      {mainAuth === '1' ? (
        <>
          <ToolTip content="チェックを付けると全店参照権限が付与されます。">
            <label htmlFor="viewall">
              <input type="checkbox" {...register('viewAllFlg')} id="viewall" disabled className="me-2" />
              全店舗参照権限
            </label>
          </ToolTip>
        </>
      ) : (
        <>
          <ToolTip content="チェックを付けると全店参照権限が付与されます。">
            <label htmlFor="viewall">
              <input type="checkbox" {...register('viewAllFlg')} id="viewall" className="me-2" />
              全店舗参照権限
            </label>
          </ToolTip>
        </>
      )}
      <>
        <ToolTip content="チェックを付けるとメールが配信されます。">
          <label htmlFor="mailFlg">
            <input
              type="checkbox"
              {...register('mailSendFlg')}
              id="mailFlg"
              className="me-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMailFlg(e)}
            />
            メール受信設定
          </label>
        </ToolTip>
        <ToolTip content="チェックを付けるとデジタルギフト券一覧が参照可能になります。">
          <label htmlFor="gift">
            <input type="checkbox" {...register('giftViewFlg')} id="gifta" className="me-2" />
            リターンメニュー閲覧制限
          </label>
        </ToolTip>
      </>
    </div>
  );
});
